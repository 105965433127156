import { useMutation } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

export const useCreateUberEatsIntegrationMutation = () => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async ({ shopId }: { shopId: Shop['shopId'] }) => {
      const response = await authenticatedFetch.delete(
        `api/management/v1/shops/${shopId}/third_party_integration/uber-eats/disconnect`,
      );

      return response;
    },
  });
};

import { Id, shop } from './shop';

export const accountBase = (shopId?: Id) => `${shop(shopId)}/account`;

export const debitCards = (shopId?: Id) => `${accountBase(shopId)}/payouts`;

export const debitCardNew = (shopId?: Id) => `${debitCards(shopId)}/new`;

export const shopIntegrations = (shopId?: Id) =>
  `${accountBase(shopId)}/integrations`;
export const uberEatsLocations = (shopId?: Id) =>
  `${accountBase(shopId)}/uber-eats-locations`;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { RegisterUser } from 'types/register-users';

import { getRegisterUsersQueryKey } from '.';

export const useDeleteRegisterUserMutation = (
  shopId: string,
  userId: string,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      console.log(
        `I would have sent a delete request to /api/management/v1/ros/shops/${shopId}/register_users/${userId}`,
      );
      // TODO - When backend endpoint is in place uncomment the actual api call below
      // return authenticatedFetch.del(
      //   `${hostnames.restaurantApi}/api/management/v1/ros/shops/${shopId}/register_users/${userId}`,
      // );
      return Promise.resolve(userId);
    },
    onSuccess: () => {
      queryClient.setQueryData<RegisterUser[]>(
        getRegisterUsersQueryKey(shopId),
        (old = []) => old.filter((user) => user.id !== userId),
      );
    },
  });
};

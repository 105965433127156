import { useMutation } from '@tanstack/react-query';

import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

import useApi from './use-api';

type Props = {
  subject: string;
  to: string;
};

export const useSendEmailMutation = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async ({ subject, to }: Props) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/email/send_email`,
        {
          subject,
          to,
          shop_id: shopId,
        },
      );

      return camelCaseKeys(response);
    },
  });
};

import { useMutation } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { snakeCaseKeys } from 'utilities/objects';

export const useCreateUberEatsIntegrationMutation = (
  shopId: Shop['shopId'],
) => {
  const { authenticatedFetch } = useApi();

  return useMutation({
    mutationFn: async (location: { uberEatsStoreId: string }) => {
      const response = await authenticatedFetch.post(
        `api/management/v1/shops/${shopId}/third_party_integration/uber-eats/authorize/finalize`,
        snakeCaseKeys(location),
      );

      return response;
    },
  });
};

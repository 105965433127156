import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  desktopDayLabels,
  timeAsInts,
} from 'components/hours/weekly-hours/helpers';

import Day from './day';

import styles from './styles.module.scss';

const HoursEditor = ({ entries, isUpdateInProgress, saveEntries, type }) => {
  const [activeDay, setActiveDay] = useState();

  const validateAllDays = ({ dayOfWeek, from, to }) => {
    const currentDayIndex = desktopDayLabels.findIndex(
      (dayLabel) => dayLabel === dayOfWeek,
    );

    // if the entry being edited/added has a to value less than from we need to check the next day for overlapping
    if (timeAsInts(to) < timeAsInts(from)) {
      const nextDayIndex = currentDayIndex + 1 > 6 ? 0 : currentDayIndex + 1;
      const nextDay = desktopDayLabels[nextDayIndex];
      const nextDayEntries = Object.values(entries[nextDay]);

      if (
        nextDayEntries.some((entry) => timeAsInts(to) >= timeAsInts(entry.from))
      ) {
        return true;
      }
    }

    // When adding/editing an entry we also need to check the previous day to see if it has an entry
    // that overlaps into the next day and check for overlapping times
    const prevDayIndex = currentDayIndex - 1 < 0 ? 6 : currentDayIndex - 1;
    const prevDay = desktopDayLabels[prevDayIndex];
    const prevDayEntries = entries[prevDay] && Object.values(entries[prevDay]);

    if (
      prevDayEntries &&
      prevDayEntries.some((entry) => {
        return (
          timeAsInts(entry.to) < timeAsInts(entry.from) &&
          timeAsInts(from) <= timeAsInts(entry.to)
        );
      })
    ) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles.container}>
      {desktopDayLabels.map((day) => (
        <Day
          day={day}
          entries={entries[day]}
          isActive={activeDay === day}
          isUpdateInProgress={isUpdateInProgress}
          key={`${day}-${type}`}
          onClick={() => setActiveDay(activeDay !== day && day)}
          saveEntries={saveEntries}
          setActiveDay={setActiveDay}
          type={type}
          validateAllDays={validateAllDays}
        />
      ))}
    </div>
  );
};

HoursEditor.propTypes = {
  entries: PropTypes.object,
  isUpdateInProgress: PropTypes.bool.isRequired,
  saveEntries: PropTypes.func,
  type: PropTypes.string,
};

HoursEditor.defaultProps = {
  entries: {},
  saveEntries: () => {},
  type: null,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default HoursEditor;

import { useMutation, useQueryClient } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

import { Opening } from './use-update-shop-opening-mutation';

type Values = [
  {
    day_of_week: string;
    from: string;
    id: number;
    open_for: string;
    to: string;
  },
];

export const useDeleteShopOpeningMutaion = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (entries: Values) => {
      const requests = [];

      for (const values of entries) {
        requests.push(
          await authenticatedFetch
            .del(`api/management/v1/shops/${shopId}/openings/${values.id}`)
            .then(() => ({
              shopId,
              openingId: values.id,
              openFor: values.open_for,
              dayOfWeek: values.day_of_week,
              type: 'delete',
            })),
        );
      }

      return Promise.all(requests);
    },
    onSuccess: (responses) => {
      for (const data of responses) {
        queryClient.setQueryData<Opening[]>(
          [shopId, 'weeklyHours'],
          (oldHours) => {
            if (oldHours !== undefined) {
              const deletedHourIndex = oldHours.findIndex(
                (hour) => hour.id === data.openingId,
              );
              oldHours.splice(deletedHourIndex, 1);
            }
            return oldHours;
          },
        );
      }
    },
  });
};

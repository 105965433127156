import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';
import { camelCaseKeys } from 'utilities/objects';

type UberEatsRedirectResponse = { url: string; isConnected: boolean };

export const useUberEatsIntegrationRedirectQuery = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [shopId, 'uberEatsIntegration'],
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `api/management/v1/shops/${shopId}/third_party_integration/uber-eats/authorize`,
      );
      return camelCaseKeys(response) as UberEatsRedirectResponse;
    },
  });
};

import { createContext, PropsWithChildren, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { API } from 'types/api';

import * as helpers from './helpers';
import * as requests from './requests';

export const ApiContext = createContext<API | null>(null);
ApiContext.displayName = 'API';

type Props = {
  hostname: string;
};

const ApiProvider = ({ children }: PropsWithChildren<Props>) => {
  const { getAccessTokenSilently } = useAuth0();

  const api = useMemo(() => {
    const refreshAndRetryOnAuthFail = helpers.refreshAndRetryOnAuthFail(
      fetch,
      getAccessTokenSilently,
    );

    const authenticatedFetch = {
      get: requests.bodiless(
        fetch,
        refreshAndRetryOnAuthFail,
        'GET',
        getAccessTokenSilently,
      ),
      put: requests.standard(
        fetch,
        refreshAndRetryOnAuthFail,
        'PUT',
        getAccessTokenSilently,
      ),
      patch: requests.standard(
        fetch,
        refreshAndRetryOnAuthFail,
        'PATCH',
        getAccessTokenSilently,
      ),
      post: requests.standard(
        fetch,
        refreshAndRetryOnAuthFail,
        'POST',
        getAccessTokenSilently,
      ),
      del: requests.bodiless(
        fetch,
        refreshAndRetryOnAuthFail,
        'DELETE',
        getAccessTokenSilently,
      ),

      // This version of delete can handle consuming a response with a body
      // (usually a copy of the object being deleted).
      delete: requests.standard(
        fetch,
        refreshAndRetryOnAuthFail,
        'DELETE',
        getAccessTokenSilently,
      ),
      postFile: requests.postFile(
        fetch,
        refreshAndRetryOnAuthFail,
        getAccessTokenSilently,
      ),
    };

    // As we move towards moving endpoints to be collocated with their respective query/mutation hooks
    // we include the authenticatedFetch methods here to allow those hooks access to the base
    // authenticated http functions as well as within the existing createEndpoints function
    return {
      authenticatedFetch,
    };
  }, [getAccessTokenSilently]);

  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default ApiProvider;

import { useQuery } from '@tanstack/react-query';

import useApi from 'hooks/use-api';
import { Shop } from 'types/shops';

type UberEatsLocationsResponse = { id: string; name: string };

export const useUberEatsLocationsQuery = (shopId: Shop['shopId']) => {
  const { authenticatedFetch } = useApi();

  return useQuery({
    queryKey: [shopId, 'uberEatsLocations'],
    queryFn: async () => {
      const response = await authenticatedFetch.get(
        `/api/management/v1/shops/${shopId}/third_party_integration/uber-eats/stores`,
      );

      return response as UberEatsLocationsResponse[];
    },
  });
};

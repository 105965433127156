import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import SubmitFooter from 'components/shared/submit-footer';
import {
  removeMenuProductsSearchSynchronous,
  setMenuProductsScrollIdSynchronous,
} from 'hooks/menu/use-products-page-state';
import { useSaveProductMutation } from 'hooks/menu/use-save-product-mutation';
import useAnalytics from 'hooks/use-analytics';
import { useSendEmailMutation } from 'hooks/use-send-email-mutation';
import * as paths from 'routes/paths';
import {
  showInvalidSubmitToast,
  showUnexpectedErrorToast,
} from 'utilities/forms';
import { indexBy } from 'utilities/lists';
import {
  getDefaultFormValues,
  getMenuQualityAlerts,
  getProductEditAnalytics,
} from 'utilities/menu';

import ProductForm from './form';

const MenuProductPageContent = ({
  isRosEnabled,
  isKitchenNameEnabled,
  menuResponse,
  printSettings,
  productResponse,
  refetchMenu,
  shopId,
  shopName,
}) => {
  const navigate = useNavigate();
  const { trackMenuProductCreated, trackProductUpdate } = useAnalytics();

  const [defaultValues] = useState(() =>
    getDefaultFormValues(productResponse, printSettings),
  );

  const isNew = productResponse == null;

  const {
    control,
    formState: { errors, isSubmitting: isFormValidating, submitCount },
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger,
  } = useForm({
    defaultValues,
    mode: 'onBlur',
  });

  const { categoriesById, categoryOptions, productsById } = useMemo(() => {
    const categories = menuResponse.relationships.categories ?? [];
    const products = menuResponse.relationships.products ?? [];
    const categoryIds = menuResponse.menu.categoryIds ?? [];

    const categoriesById = indexBy(categories, 'id');
    const productsById = indexBy(products, 'id');
    const categoryOptions = [];

    for (const id of categoryIds) {
      if (categoriesById[id] == null) {
        continue;
      }

      categoryOptions.push({
        label: categoriesById[id].name,
        value: id,

        // Allows for filtering empty categories in customizations modal.
        length: categoriesById[id].productIds?.length ?? -1,
      });
    }

    return {
      categoriesById,
      categoryOptions,
      productsById,
    };
  }, [menuResponse]);

  const {
    isLoading: isProductSaving,
    isSuccess: isProductSaved,
    mutate: saveProduct,
  } = useSaveProductMutation(shopId, isKitchenNameEnabled);

  const { mutate: sendEmail } = useSendEmailMutation(shopId);

  const handleValidSubmit = (values) => {
    saveProduct(values, {
      onError: async (error) => {
        if (error.status === 400) {
          // The product name validation uses the menu query, so let's
          // invalidate it before triggering validation again.
          await refetchMenu();

          // We are assuming that we have implemented client-side validation for
          // all possible request issues. This assumption is a valid tradeoff
          // given that the API constraints are documented and that we generally
          // do not show strings from the API response directly to users. In
          // other words, we would end up with logic to map each error to custom
          // string anyway.
          trigger();
          showInvalidSubmitToast();
        } else {
          showUnexpectedErrorToast();
        }
      },
      onSuccess: (response) => {
        if (isNew) {
          trackMenuProductCreated({
            shopId,
            isFeatured: response.product.isFeatured ?? false,
            productName: response.product.name,
            productDescription: response.product.description,
            productTypes: response.relationships.productTypes.map((it) => ({
              name: it.name,
              price: it.price,
            })),
            categoryId: response.product.categoryId,
          });

          // For new products, we never maintain the search value. We could keep
          // the search query if the new product matches it, but we cannot think
          // of a workflow in which that logic is advantageous.
          removeMenuProductsSearchSynchronous();
          setMenuProductsScrollIdSynchronous(`product-${response.product.id}`);
        } else {
          // If the category is changed, we clear any search and take the user
          // to the top of the new category. Otherwise, we take them directly to
          // the product, regardless of the search.
          if (
            response.product.categoryId !== productResponse.product.categoryId
          ) {
            removeMenuProductsSearchSynchronous();
            setMenuProductsScrollIdSynchronous(
              `category-${response.product.categoryId}`,
            );
          } else {
            setMenuProductsScrollIdSynchronous(
              `product-${response.product.id}`,
            );
          }

          trackProductUpdate({ shopId, ...getProductEditAnalytics(values) });
        }

        const { updates } = getMenuQualityAlerts(
          shopName,
          defaultValues,
          values,
        );

        if (updates) {
          sendEmail({ subject: updates, to: 'updates' }).catch(() => {
            // Ignore this error as there is nothing to do.
          });
        }

        toast.success(`${response.product.name} Saved!`);
      },
    });
  };

  useEffect(() => {
    if (isProductSaved) {
      navigate(paths.getMenuItemsPath(shopId));
    }
  }, [isProductSaved, navigate, shopId]);

  const isSubmitting = isFormValidating || isProductSaving;

  return (
    <ProductForm
      categoriesById={categoriesById}
      categoryOptions={categoryOptions}
      control={control}
      errors={errors}
      getValues={getValues}
      isRosEnabled={isRosEnabled}
      isKitchenNameEnabled={isKitchenNameEnabled}
      onSubmit={handleSubmit(handleValidSubmit, showInvalidSubmitToast)}
      productId={String(productResponse?.product?.id ?? 'new')}
      productsById={productsById}
      register={register}
      setValue={setValue}
      submitCount={submitCount}
      shopId={shopId}
    >
      <SubmitFooter
        onCancel={() => navigate(-1)}
        isSubmitting={isSubmitting}
        isSubmitDisabled={isSubmitting}
        submitButtonChameleonTarget="Menu Product Save Button"
        submitButtonDataTestId="menuProductPageSaveButton"
      />
    </ProductForm>
  );
};

MenuProductPageContent.propTypes = {
  isRosEnabled: PropTypes.bool,
  isKitchenNameEnabled: PropTypes.bool,
  menuResponse: PropTypes.object.isRequired,
  printSettings: PropTypes.array.isRequired,
  productResponse: PropTypes.object,
  refetchMenu: PropTypes.func.isRequired,
  shopId: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
};

/* eslint-disable-next-line import/no-default-export -- This default export
 * existed before we decided to ban them. If you are working on this file,
 * please consider changing this import to a named import. */
export default MenuProductPageContent;
